import React from "react"
import { DefaultHeader } from "components/headers/default-header"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { config } from "@fortawesome/fontawesome-svg-core"
import { CvBtn } from "components/case_study/cv-btn"
import { StarRates } from "components/case_study/star-rates"
import { FlowNumber } from "components/case_study/flow-number"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
  faArrowDown,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons"
config.autoAddCss = false

// キャンペーン概要
const features = [
  { color: "bg-case_blue", title: "展开系数", content: "中等" },
  {
    color: "bg-case_orange",
    title: "抽签法",
    content: "后期抽签",
  },
  { color: "bg-accent", title: "如何检查结果", content: "DM" },
]

// こんな方におすすめ文言
const recommends = [
  { title: "组织运动的预算低" },
  { title: "这个运动本身需要引起轰动" },
  { title: "想进一步提高他们公司的知名度" },
]

const TwitterFollowAndQuoteTweet = ({ location }) => {
  return (
    <>
      <DefaultHeader lang="zh-CN" />
      <Layout lang="zh-CN">
        <Seo
          pagetitle="推特活动案例研究，标签式引用转发"
          pagepath={location.pathname}
          pagedesc="通过关注活动组织者的推特账户，并引用和转发带有指定标签的活动推文，就可以参加这项活动。 虽然不如 关注与转发 活动那样扩散，但它与追随者建立了关系并获得了粉丝。"
        />
        <main className="case_study main">
          {/* メインビジュアル */}
          <section className="hero h-auto">
            <div className="inner relative py-5 md:py-8 px-4">
              <h1 className="text-white font-bold md:mb-4 mb-3">
                关注＆
                <br className="block md:hidden" />
                hashtag引用转发
              </h1>
              <p className="text-white md:text-xl text-lg font-bold leading-relaxed">
                关注主办方账户，引用并转发带有指定标签的活动推文，
                <br className="md:block hidden" />
                以完成参赛程序。
                <br className="md:block hidden" />
                虽然比起关注和转发活动，这种活动的扩散性较差，
                <br className="md:block hidden" />
                但可以与粉丝建立关系，获得粉丝。
              </p>
              <div className="fv_img absolute -right-8 top-32">
                <StaticImage
                  layout="fullWidth"
                  alt="关注＆hashtag引用转发"
                  src="../../../../images/case_study/mv_follow_and_quote_tweet.png"
                />
              </div>
            </div>
          </section>
          {/* こんな方におすすめです */}
          <section className="recommend bg-secondary">
            <div className="inner py-6 md:pt-10 md:pb-28 px-4">
              <ul className="flex md:mb-8 mb-6 gap-2">
                {features.map((feature, index) => {
                  return (
                    <li key={index} className="text-center font-bold">
                      <p
                        className={`text-white ${feature.color} py-1 md:px-6 px-1 text-sm md:text-xl`}
                      >
                        {feature.title}
                      </p>
                      <p className="text-sm md:text-xl bg-white py-1 md:px-6 px-3">
                        {feature.content}
                      </p>
                    </li>
                  )
                })}
              </ul>
              <h2 className="text-2xl md:text-3xl text-primary font-bold pb-2 border-b-2 border-primary md:w-3/4 mb-3">
                推荐使用
              </h2>
              <div className="">
                {recommends.map((recommend, index) => {
                  return (
                    <div key={index} className="flex items-center mb-1">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-primary mr-3"
                      />
                      <p className="font-bold text-text md:text-xl">
                        {recommend.title}
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
          {/* キャンペーンの特徴 */}
          <section className="campaign-feat bg-white">
            <div className="inner md:py-20 px-4 pt-8 pb-10">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                运动的特点
              </h2>
              <div className="flex md:flex-row flex-col md:gap-10 gap-4">
                <div className="flex gap-4 flex-1">
                  <div className="border border-lightgrey flex-1">
                    <h3 className="py-2 font-bold bg-case_blue text-white text-center text-sm md:text-base">
                      参与者
                    </h3>
                    <div className="md:p-4 p-3">
                      <p className="font-bold text-sm text-text mb-1">
                        扩散系数
                      </p>
                      <StarRates rate={3} />
                      <hr className="border-dashed border border-lightgrey my-4" />
                      <p className="font-bold text-sm text-text mb-1">
                        参与的便利性
                      </p>
                      <StarRates rate={4} />
                      <hr className="border-dashed border border-lightgrey my-4" />
                      <p className="font-bold text-sm text-text mb-1">激励</p>
                      <StarRates rate={2.5} />
                    </div>
                  </div>
                  <div className="border border-lightgrey flex-1">
                    <h3 className="py-2 font-bold bg-primary text-white text-center text-sm md:text-base">
                      联络人
                    </h3>
                    <div className="md:p-4 p-3">
                      <p className="font-bold text-sm text-text mb-1">缓解</p>
                      <StarRates rate={4} />
                    </div>
                  </div>
                </div>
                <p className="flex-1 text-text">
                  这些运动的扩散情况因运动的性质而有很大不同。
                  <br />
                  如果活动本身被谈论，它可以大大增加追随者的数量。
                  <br />
                  <br />
                  与关注和转发活动相比，用户参与的障碍更高，因为参与是通过关注和引用带标签的转发。
                  <br />
                  此外，用户参与的积极性一般，因为他们不会立即得到结果的通知。
                  <br />
                  <br />
                  在活动期间，负责人不产生任何工作，但在活动结束后，需要单独检查参与者的资料并发送DM，这需要负责人做适量的工作。
                  <br />
                  如果只向获胜者发送DM，这可以不使用活动工具来完成。
                </p>
              </div>
            </div>
          </section>
          {/* 弊社でのキャンペーン実績 */}
          {/* <section className="campaign_results">
            <h2 className="title font-semibold text-center">弊社でのキャンペーン実績</h2>
            <div className="inner">
              <div className="left bg-white rounded">
                <h3 className="results_title text-xl leading-normal font-semibold mb-2">〇〇株式会社様</h3>
                <p className="date text-xs leading-normal">yyyy年mm月dd日〜yyyy年mm月dd日（dd日間）</p>
                <div className="img_box rounded">
                  <StaticImage
                    layout=""
                    alt=""
                    src=""
                  />
                </div>
                <ul className="detail_top flex justify-between">
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">リツイート数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">参加者数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">フォロワー増加数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                </ul>
                <ul className="detail_bottom">
                  <li className="text-center">
                    <h4 className="bottom_title text-sm leading-normal font-semibold py-1 mb-2"><span>*</span>CPF</h4>
                    <p className="bottom_description font-semibold mb-2">000円</p>
                  </li>
                </ul>
                <p className="caution text-xs leading-normal text-right"><span>*</span>フォロワー1人獲得当たりの単価</p>
              </div>
              <div className="right bg-white rounded">
                <h3 className="results_title text-xl leading-normal font-semibold mb-2">〇〇株式会社様</h3>
                <p className="date text-xs leading-normal">yyyy年mm月dd日〜yyyy年mm月dd日（dd日間）</p>
                <div className="img_box rounded">
                  <StaticImage
                    layout=""
                    alt=""
                    src=""
                  />
                </div>
                <ul className="detail_top flex justify-between">
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">リツイート数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">参加者数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">フォロワー増加数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                </ul>
                <ul className="detail_bottom">
                  <li className="text-center">
                    <h4 className="bottom_title text-sm leading-normal font-semibold py-1 mb-2"><span>*</span>CPF</h4>
                    <p className="bottom_description font-semibold mb-2">000円</p>
                  </li>
                </ul>
                <p className="caution text-xs leading-normal text-right"><span>*</span>フォロワー1人獲得当たりの単価</p>
              </div>
            </div>
          </section> */}
          {/* PARKLoTのキャンペーンツールを導入するとどう変わる？ */}
          <section className="before_after bg-secondary">
            <div className="inner pt-8 pb-10 md:py-20 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                实施PARKLoT的运动工具会
                <br className="block md:hidden" />
                如何改变现状？
              </h2>
              <p className="mb-6 text-text">
                引入PARKLoT的活动工具可以大大减少操作时间!
                <br />
                检查活动流程，看看哪里可以减少。
              </p>
              <div className="flex flex-col md:flex-row md:gap-10 gap-4">
                <div className="flex-1 border border-primary rounded bg-white">
                  <h3 className="bg-primary font-bold text-xl leading-normal text-center text-white py-3">
                    如果活动是手动进行的
                  </h3>
                  <div className="rounded-b p-4">
                    <div className="rounded-full text-center text-sm text-text border border-primary py-2">
                      通过Twitter打开相关活动页面
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded-full text-center text-sm text-text border border-primary py-2">
                      查看参与者的名单
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded py-4 bg-case_paleblue">
                      <div className="flex justify-center items-center mb-2">
                        <div className="text-center text-sm bg-white border border-primary rounded-full py-2 px-3">
                          检查用户详情屏幕
                        </div>
                        <div className="mx-2">
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="text-lightgrey text-base"
                          />
                        </div>
                        <div className="text-center text-sm bg-white border border-primary rounded-full py-2 px-3">
                          获奖者的确定
                        </div>
                      </div>
                      <p className="text-center text-sm font-bold text-primary">
                        有多少个赢家就重复多少次
                      </p>
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded py-4 bg-case_paleblue mb-4 text-center">
                      <div className="text-center inline-block text-sm bg-white border border-primary rounded-full py-2 px-3">
                        搜索获奖者的账户
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="text-center inline-block text-sm bg-white border border-primary rounded-full py-2 px-3 mb-2">
                        DM的创建、确认和传输
                      </div>
                      <p className="text-center text-sm font-bold text-primary">
                        有多少个赢家就重复多少次
                      </p>
                    </div>
                    <h3 className="text-center font-bold text-text mb-3">
                      仅仅完成运动就
                      <br className="block md:hidden" />
                      <span className="font-bold text-2xl text-accent">
                        需要3-5小时
                      </span>
                    </h3>
                    <ul className="text-xs list-disc leading-normal py-4 pr-4 pl-8 bg-palegrey rounded">
                      <li>
                        需要登录Twitter，
                        <span className="font-bold">
                          逐一查看每个参与者的资料
                        </span>
                      </li>
                      <li>
                        需要
                        <span className="font-bold">逐一给获奖者发送DM</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="border border-accent rounded md:mb-10 mb-4">
                    <h3 className="font-bold text-xl leading-normal bg-accent text-center text-white py-3">
                      当使用PARKLoT时
                    </h3>
                    <div className="bg-white rounded-b p-4">
                      <div className="rounded-full text-center text-sm text-text border border-accent py-2">
                        获奖者是由PARKLoT创建的CSV决定的
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="rounded-full text-center text-sm text-text border border-accent py-2">
                        创建获奖者名单的CSV
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="text-center text-sm font-bold text-accent bg-accent_pale rounded leading-normal py-4 mb-4">
                        PARKLoT向获奖者批量发送DM
                      </div>
                      <h3 className="text-center text-text font-bold mb-3">
                        该活动需要
                        <br className="md:hidden block" />
                        <span className="text-2xl text-accent leading-normal">
                          20-30分钟才能完成!
                        </span>
                      </h3>
                      <ul className="text-xs list-disc text-text leading-normal py-4 pr-4 pl-8 bg-palegrey rounded">
                        <li>
                          <span className="font-bold">
                            查看CSV格式的分类和过滤后的
                          </span>
                          参与者名单。
                        </li>
                        <li>
                          <span className="font-bold">
                            你可以要求PARKLoT发送一批获胜和失败的DM
                          </span>
                        </li>
                        <li>
                          （作为次要效果）
                          <span className="font-bold">
                            可以对申请人的趋势进行分析，并用于未来的活动
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="bottom border border-lightgrey rounded">
                    <h3 className="text-xl font-bold bg-lightgrey leading-normal text-center text-white py-3">
                      在以下情况下，
                      <br className="block md:hidden" />
                      不建议使用工具
                    </h3>
                    <div className="bg-white rounded-b py-4 pr-4 pl-8">
                      <ul className="font-bold text-text list-disc leading-normal md:p-4 p-1">
                        <li>不需要申请人的信息</li>
                        <li>更少的赢家</li>
                        <li>没有实施工具的费用</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* 施策内容や見積もりについて、お気軽にご相談ください */}
          <CvBtn />
          {/* PARKLoTを使用した際の具体的なキャンペーン手順について */}
          <section className="usage_flow bg-white">
            <div className="inner md:py-20 pt-8 pb-10 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                使用PARKLoT时的
                <br className="block md:hidden" />
                具体运动程序
              </h2>
              <p className="mb-6 text-text">
                现在让我详细解释一下使用PARKLoT时的运动程序吧！
              </p>
              <FlowNumber
                num="01"
                parklot={false}
                diagram={true}
                title="请向PARKLoT提供您的活动信息"
              >
                <p className="text-text">
                  在活动开始前至少三个工作日，请向我们提供有关该活动的以下信息
                  <br />
                  我们将为你设置数据。
                </p>
                <div className="md:w-3/5 bg-white rounded py-4 px-4">
                  <h4 className="font-semibold text-center mb-3">
                    要提供的信息
                  </h4>
                  <ul className="text-xs leading-normal list-disc md:p-4 p-1">
                    <li>组织者的Twitter账户名称</li>
                    <li>开始日期和时间</li>
                    <li>结束日期和时间</li>
                  </ul>
                </div>
              </FlowNumber>

              <FlowNumber
                num="02"
                parklot={false}
                title="在Twitter上发布你的竞选推文"
              >
                <p className="text-text">在推特上发布一条关于该活动的推文</p>
              </FlowNumber>

              <FlowNumber num="03" parklot={true} title="开始收集引言和转发">
                <p className="text-text">
                  我们开始收集引用和转发竞选推文的用户。
                </p>
              </FlowNumber>

              <FlowNumber
                num="04"
                parklot={true}
                title="申请人名单的CSV将被发送给负责人"
              >
                <p className="text-text">
                  你将收到一个CSV文件，其中包含活动参与者的名单。
                  <br />
                  请检查CSV以确定获奖者。
                </p>
              </FlowNumber>

              <FlowNumber
                num="05"
                parklot={false}
                diagram={true}
                title="确定获奖者并以CSV格式交给PARKLoT"
              >
                <p className="text-text">
                  选定获奖者后，您将被要求创建一个CSV，我们将需要这个CSV来联系获奖者。
                  <br />
                  获奖的DM文本也将与你分享。
                  <br />
                  <br />
                  当你向我们发送你的CSV时，也请向我们提供获奖文本，通过DM发送给你。
                </p>
                <div>
                  <div className="bg-white rounded py-4 px-4">
                    <h4 className="font-semibold text-center mb-3">
                      要提供的信息
                    </h4>
                    <ul className="text-xs leading-normal list-disc md:p-4 p-1">
                      <li>用户ID</li>
                      <li>帐号</li>
                      <li>账户名称</li>
                      <li>奖项名称</li>
                    </ul>
                  </div>
                  <p className="text-text">(例子)</p>
                  <StaticImage
                    layout="constrained"
                    alt="要提供的信息"
                    src="../../../../images/case_study/winners_csv.png"
                  />
                </div>
              </FlowNumber>

              <FlowNumber
                num="06"
                parklot={true}
                diagram={true}
                last={true}
                title="将向获奖者发送批量DM"
              >
                <p className="text-text">
                  根据负责人发送的获奖文本和CSV，我们将向每位获奖者发送一批DM。
                </p>
                <div>
                  <StaticImage
                    layout="constrained"
                    alt="DM给获奖者"
                    src="../../../../images/case_study/usage_flow05.png"
                  />
                </div>
              </FlowNumber>
            </div>
          </section>
          {/* PARKLoTの月額料金に含まれる機能は？ */}
          <section className="parklot_function bg-secondary">
            <div className="inner md:py-20 pt-8 pb-10 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                PARKLoT的月费
                <br className="block md:hidden" />
                中包括哪些功能？
              </h2>
              <p className="text-text md:mb-10 mb-5">
                在月费范围内，可提供一般关注和标签引用转推活动。
              </p>
              <h3 className="text-lg text-text font-bold text-center md:mb-6 mb-4">
                月费中包含的功能
              </h3>
              <div className="flex md:flex-row flex-col gap-4 md:gap-10 place-content-center">
                <div className="p-4 md:p-6 text-center bg-white">
                  <StaticImage
                    layout="constrained"
                    alt="即时赢取运动的实施成本"
                    src="../../../../images/case_study/parklot_function01.png"
                    objectfit="contain"
                    className="w-28 mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text">检索有标签的引文转发</h4>
                </div>
                <div className="p-4 md:p-6 text-center bg-white">
                  <StaticImage
                    layout="constrained"
                    alt=" 参与者信息的CSV输出"
                    src="../../../../images/case_study/parklot_function02.png"
                    objectfit="contain"
                    className="w-28 mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text mb-1">
                    提供运动参与者名单的CSV
                  </h4>
                  <p className="text-text text-xs">
                    *如果需要进行复杂的定制，
                    <br />
                    可能会收取额外费用
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/* 施策内容や見積もりについて、お気軽にご相談ください */}
          <CvBtn />
        </main>
      </Layout>
    </>
  )
}

export default TwitterFollowAndQuoteTweet
